import { PaginationWithProgressProps } from './props';
import PaginationWithProgressSkeleton from './Skeleton';

import styles from './styles.module.scss';
import { Button } from '../Button';
import { useTranslation } from '../../hooks';

export function PaginationWithProgress({
  count,
  fetchNextPage,
  hasNextPage,
  loading,
  isFetchingNextPage,
  totalCount,
}: PaginationWithProgressProps) {
  const { t } = useTranslation('common');

  function handleFetchNextPage() {
    if (fetchNextPage != undefined) {
      fetchNextPage();
    }
  }

  if (loading) {
    return <PaginationWithProgressSkeleton />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.container__wrapper}>
        <div className={styles.container__wrapper__progress__container}>
          <div
            className={styles.container__wrapper__progress}
            style={{ width: `${(count / totalCount) * 100}%` }}
          />
        </div>
        <Button
          isSecondary
          disabled={isFetchingNextPage || !hasNextPage}
          onClick={handleFetchNextPage}
        >
          {t(
            !hasNextPage
              ? 'no_more_items_label'
              : 'pagination_load_more_button',
          )}
        </Button>
      </div>
    </div>
  );
}
