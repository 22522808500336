import { SliderProps } from './props';
import Content from './Content';

import styles from './styles.module.scss';
import { PropsWithChildren } from 'react';
import { useCustomCarousel } from '@starsoft/common/hooks';
import LoadMore from './LoadMore';

export function Slider({
  size,
  timeout,
  children,
  paginated,
  className,
  mobileSize,
  hideButtons,
  slideBy = 1,
  currentIndex,
  onStepChange,
  useAutoScroll,
  absoluteButton,
  customComponents,
  showButtonsOnScroll,
  scrollToCurrentOnEnter,
  direction,
}: PropsWithChildren<SliderProps>) {
  const { handleDragLeft, handleDragRight, ...rest } = useCustomCarousel({
    autoScroll: useAutoScroll,
    size,
    onStepChange,
    timeout,
    currentIndex,
    scrollToCurrentOnEnter,
    slideBy,
    direction,
  });

  return (
    <div className={`${styles.container} ${className ? className : ''}`}>
      {customComponents?.header
        ? customComponents?.header({
            onDragLeft: () => handleDragLeft(),
            onDragRight: () => handleDragRight(),
          })
        : null}
      <Content
        showButtonsOnScroll={showButtonsOnScroll}
        size={size}
        containerProps={{ ...rest }}
        hideButtons={hideButtons}
        absoluteButton={absoluteButton}
        mobileSize={mobileSize}
        slideBy={slideBy}
      >
        {children}
        {paginated && !paginated?.loading && <LoadMore {...paginated} />}
      </Content>
    </div>
  );
}
