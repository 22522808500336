import { PaginationWithProgress } from '@starsoft/common/components';
import { PaginationWithProgressProps } from '../../PaginationWithProgress/props';
import styles from './styles.module.scss';

export default function LoadMore(props: PaginationWithProgressProps) {
  return (
    <div className={styles.container}>
      <PaginationWithProgress {...props} />
    </div>
  );
}
