import { PropsWithChildren } from 'react';
import { CarouselProps } from './props';
import CarouselHeader from './Header';
import { Slider } from '@starsoft/common/components';

function Carousel({
  icon,
  label,
  redirect,
  children,
  secondaryStyles,
  color,
  useTransComponent,
  useAutoScroll = false,
  filterComponent,
  size,
  mobileSize,
  customIcon,
  paginated,
  direction,
}: PropsWithChildren<CarouselProps>) {
  return (
    <Slider
      size={size}
      direction={direction}
      customComponents={{
        header: ({ onDragLeft, onDragRight }) => (
          <CarouselHeader
            icon={icon}
            color={color}
            useTransComponent={useTransComponent}
            label={label}
            dragLeft={onDragLeft ? onDragLeft : () => undefined}
            dragRight={onDragRight ? onDragRight : () => undefined}
            redirect={redirect}
            customIcon={customIcon}
            secondaryStyles={secondaryStyles}
            filterComponent={filterComponent}
          />
        ),
      }}
      hideButtons
      paginated={paginated}
      mobileSize={mobileSize}
      useAutoScroll={useAutoScroll}
    >
      {children}
    </Slider>
  );
}

export default Carousel;
