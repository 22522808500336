import { GameCardProps } from './props';
import { useResolvedTheme } from '@/hooks/theme/useResolvedTheme';
import { SettingLayoutComponentId } from '@starsoft/common/models';
import { ComponentTypeId } from '@starsoft/common/models';
import dynamic from 'next/dynamic';
import { memo } from 'react';

const GameCardV1 = dynamic(() => import('./V1'), { ssr: true });
const GameCardV2 = dynamic(() => import('./V2'), { ssr: true });
const GameCardV3 = dynamic(() => import('./V3'), { ssr: true });

function GameCard({ version, ...props }: GameCardProps): JSX.Element {
  const { components } = useResolvedTheme();
  const coallescedVersion: SettingLayoutComponentId =
    version ?? components.get(ComponentTypeId.GameCard);

  switch (coallescedVersion) {
    case SettingLayoutComponentId.GameCardV1:
      return <GameCardV1 {...props} />;
    case SettingLayoutComponentId.GameCardV2:
      return <GameCardV2 {...props} />;
    case SettingLayoutComponentId.GameCardV3:
      return <GameCardV3 {...props} />;
    default:
      return <GameCardV1 {...props} />;
  }
}

export default memo(GameCard);
